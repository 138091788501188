<script setup>
defineProps({
    type: {
        type: String,
        default: 'submit',
    },
    href: {
        type: String,
        default: null,
    },
});
</script>

<template>
    <button
        @click="goto"
        :type="type"
        class="buttonClass h-12 w-full focus:outline-none text-kvpText bg-kvpGreen focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:focus:ring-green-800 flex items-center justify-center"
    >
        <slot />
    </button>
</template>

<script>
export default {
    methods: {
        goto() {
            if (this.href !== null){
                this.$inertia.visit(this.href)
            }
        }
    }
}
</script>
